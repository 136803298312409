import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoSize } from '../../interfaces/carrgo-size.interface';

export const CargoeSizeActions = createActionGroup({
  source: 'CargoSize',
  events: {
    'load_CargoSize_List': emptyProps(),
    'load_CargoSizes_Success': props<{ CargoSizes: CargoSize[] }>(),
    'load_CargoSizes_Failure': errorProps(),
    'load_CargoSize_List_By-Family_Id': props<{ familyId: string }>(),
    'load_CargoSizes_By-Family_Id_Success': props<{ CargoSizes: CargoSize[] }>(),
    'load_CargoSizes_By-Family_Id_Failure': errorProps(),
    'clear_CargoSizes': emptyProps(),
  },
});
