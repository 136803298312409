<div
  *ngIf="operatorState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Operator</h4>
  </div>
  <mat-dialog-content *lhaLoading="operatorState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveOperator()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Operator Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Operator name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="locationIds"
            bindValue="locationId"
            bindLabel="name"
            [items]="locations()"
            placeholder="Locations"
          >
          </lha-multiselect>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="reportTypeIds"
            bindValue="reportTypeId"
            bindLabel="name"
            [items]="reportTypes()"
            placeholder="Report Types"
          >
          </lha-multiselect>
        </div>
      </div>
      <div class="form__box form__box--mb20">
        <mat-slide-toggle formControlName="isActive"
          >Is Active</mat-slide-toggle
        >
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>
