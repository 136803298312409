import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DangerousGoodService } from '../../dangerous-good.service';
import { DangerousGoodActions } from '../actions/dangerous-good.actions';
import { DangerousGood } from '../../interfaces/dangerous-good.interface';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { DangerousGoodAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/dangerous-good-add-edit/dangerous-good-add-edit.component';
import { Store } from '@ngrx/store';
import { dangerousGoodsFeature } from '../features';
import { DialogOptions } from '../../../config/dialog-options';
import { LocationActions } from '../actions/locations.actions';

export const loadDangerousGoodsPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(DangerousGoodActions.load_Dangerous_Good_Page),
      switchMap(() =>
        of(
          DangerousGoodActions.load_Dangerous_Goods(),
          LocationActions.load_Locations()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadDangerousGoods = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    dangerousGoodService = inject(DangerousGoodService)
  ) => {
    return actions.pipe(
      ofType(
        DangerousGoodActions.load_Dangerous_Goods,
        DangerousGoodActions.update_Dangerous_Good_Queries,
        DangerousGoodActions.remove_Dangerous_Good_Success,
        DangerousGoodActions.add_Dangerous_Good_Success,
        DangerousGoodActions.edit_Dangerous_Good_Success
      ),
      concatLatestFrom(() => store.select(dangerousGoodsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        dangerousGoodService.loadDangerousGoods(action).pipe(
          map((res: DangerousGood[]) =>
            DangerousGoodActions.load_Dangerous_Goods_Success({
              dangerousGoods: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(DangerousGoodActions.load_Dangerous_Goods_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeDangerousGood = createEffect(
  (
    actions = inject(Actions),
    dangerousGoodService = inject(DangerousGoodService)
  ) => {
    return actions.pipe(
      ofType(DangerousGoodActions.remove_Dangerous_Good),
      mergeMap((action) =>
        dangerousGoodService.removeDangerousGood(action.id).pipe(
          map((res: DangerousGood) =>
            DangerousGoodActions.remove_Dangerous_Good_Success({
              dangerousGood: res,
              successMessage: 'Dangerous Good removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DangerousGoodActions.remove_Dangerous_Good_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addDangerousGood = createEffect(
  (
    actions = inject(Actions),
    dangerousGoodService = inject(DangerousGoodService)
  ) => {
    return actions.pipe(
      ofType(DangerousGoodActions.add_Dangerous_Good),
      mergeMap((action) =>
        dangerousGoodService.addDangerousGood(action.dangerousGood).pipe(
          map((res: DangerousGood) =>
            DangerousGoodActions.add_Dangerous_Good_Success({
              dangerousGood: res,
              successMessage: 'Dangerous Good added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DangerousGoodActions.add_Dangerous_Good_Failure({ error: error })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editDangerousGood = createEffect(
  (
    actions = inject(Actions),
    dangerousGoodService = inject(DangerousGoodService)
  ) => {
    return actions.pipe(
      ofType(DangerousGoodActions.edit_Dangerous_Good),
      mergeMap((action) =>
        dangerousGoodService
          .editDangerousGood(action.dangerousGoodId, action.dangerousGood)
          .pipe(
            map((res: DangerousGood) =>
              DangerousGoodActions.edit_Dangerous_Good_Success({
                dangerousGood: res,
                successMessage: 'Bulk Type edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                DangerousGoodActions.edit_Dangerous_Good_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_Dangerous_Goods_By_Location_Id = createEffect(
  (
    actions = inject(Actions),
    dangerousGoodService = inject(DangerousGoodService)
  ) => {
    return actions.pipe(
      ofType(DangerousGoodActions.load_Dangerous_Goods_By_Location_Id),
      switchMap(({ locationId }) =>
        dangerousGoodService.loadDangerousGoodsByLocationId(locationId).pipe(
          map((dangerousGoods) =>
            DangerousGoodActions.load_Dangerous_Goods_By_Location_Id_Success({
              dangerousGoods,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DangerousGoodActions.load_Dangerous_Goods_By_Location_Id_Error({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openDangerousGoodDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(DangerousGoodActions.open_Dangerous_Good_Dialog),
      tap((action) => {
        dialog.open(DangerousGoodAddEditComponent, {
          ...dialogOptions,
          data: {
            DangerousGood: action.dangerousGood,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
