import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { cargoTypeInitialState } from '../states/cargo-type.state';
import { CargoeTypeActions } from '../actions/cargo-type.actions';

export const cargoTypeReducer = createReducer(
  cargoTypeInitialState,
  immerOn(
    CargoeTypeActions.load_CargoType_List,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(CargoeTypeActions.load_CargoTypes_Success, (state, { CargoTypes }) => {
    state.cargoTypes = CargoTypes;
    state.loading.list = false;
  }),
  immerOn(CargoeTypeActions['load_CargoTypes_By-Family_Id_Success'], (state, { CargoTypes }) => {
    state.cargoTypes = CargoTypes;
    state.loading.list = false;
  }),
  immerOn(
    CargoeTypeActions.load_Cargotypes_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoeTypeActions.clear_CargoTypes,
    (state) => {
      state.cargoTypes = [];
    }
  )
);

export const cargotypeFeature = createFeature({
  name: 'cargoType',
  reducer: cargoTypeReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});
