import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CargoFamily } from './interfaces/carrgo-family.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CargoFamilyService {
  private readonly http = inject(HttpClient);

  loadCargoFamilies(): Observable<CargoFamily[]> {
    return this.http.get<CargoFamily[]>(`/api/CargoFamily`);
  }
}
