import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Cargo } from 'libs/services/src/lib/services/maintenance/interfaces/cargoes.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  cargoesFeature,
  locationsFeature,
  cargoFamilyFeature,
  cargotypeFeature,
  cargoSizeFeature,
  vendorsFeature,
  operatorsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { CargoesActions } from 'libs/services/src/lib/services/maintenance/store/actions/cargoes.actions';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { SingleSelectComponent } from 'libs/components/src/lib/components/single-select/single-select.component';
import { CargoeTypeActions } from 'libs/services/src/lib/services/maintenance/store/actions/cargo-type.actions';
import { CargoeSizeActions } from 'libs/services/src/lib/services/maintenance/store/actions/cargo-size.actions';
import { cargoCategories } from 'apps/allocate/src/app/shared/enums/cargo-categories';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RoleCheckService } from 'libs/services/src/lib/services/shared/role-check.service';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';
import { Location } from 'libs/services/src/lib/services/maintenance/interfaces/location.interface';

@Component({
  selector: 'lha-cargo-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    CdkDrag,
    CdkDragHandle,
    SingleSelectComponent,
  ],
  templateUrl: './cargo-add-edit.component.html',
  styleUrls: ['./cargo-add-edit.component.scss'],
})
export class CargoAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  roleCheckService = inject(RoleCheckService);
  dialogRef = inject(MatDialogRef<CargoAddEditComponent>);
  data: {
    cargo: Cargo;
    showPending: boolean;
    from?: string | null;
    to?: string | null;
  } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  cargoState = this.store.selectSignal(cargoesFeature.selectCargoesState);
  familyVm = this.store.selectSignal(cargoFamilyFeature.selectCargoFamilies);
  typeVm = this.store.selectSignal(cargotypeFeature.selectCargoTypes);
  sizeVm = this.store.selectSignal(cargoSizeFeature.selectCargoSizes);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  clients = this.store.selectSignal(operatorsFeature.selectOperators);
  vendors = this.store.selectSignal(vendorsFeature.selectVendorsList);
  cargo = this.store.selectSignal(cargoesFeature.selectCargo);
  currentUser = this.store.selectSignal(currentUserFeature.selectUser);
  isAdd = true;
  userCargoLocations: Location[] = [];

  cargoCategories = cargoCategories;
  selectedFamilyId: string = '';

  form = new FormGroup({
    ccuId: new FormControl<string>('', Validators.required),
    familyId: new FormControl<string>('', [Validators.required]),
    typeId: new FormControl<string | undefined>(undefined),
    sizeId: new FormControl<string>('', [Validators.required]),
    ccuName: new FormControl<string>({ value: '', disabled: true }),
    vendorId: new FormControl<string>('', [Validators.required]),
    description: new FormControl<string>({ value: '', disabled: true }),
    length: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0.1),
    ]),
    width: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0.1),
    ]),
    height: new FormControl<number | null>(null),
    tareMass: new FormControl<number | null>(null),
    maxGrossWeight: new FormControl<number | null>(null),
    category: new FormControl<number>(4, [Validators.required]),
    isDeckCargo: new FormControl<boolean>(true),
    isPool: new FormControl<boolean>(false),
    locationId: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
    this.checkUserRole();
  }

  checkUserRole(): void {
    const currentUser = this.currentUser();

    switch (true) {
      case this.roleCheckService.isAllocateSuperAdmin(currentUser):
        this.userCargoLocations = this.locations();
        break;
      case this.roleCheckService.isAllocateAdmin(currentUser):
        const userClientId = currentUser?.clientId;

        if(userClientId){
          const client = this.clients().find((c) => c.clientId === userClientId);

          if(client){
            this.userCargoLocations = this.locations().filter((l) => client.locationIds?.includes(l.locationId));
          }
        }
        break;
      case this.roleCheckService.isAllocateUser(currentUser):
        const userLocation = this.locations().find((l) => l.locationId === currentUser?.locationId);
        if (userLocation) {
          this.userCargoLocations = [userLocation];
        }
        break;
      default:
        const location = this.locations().find((l) => l.locationId === currentUser?.locationId);
        if (location) {
          this.userCargoLocations = [location];
        }
        break;
    }
  }

  changeFamily(event: string | null) {
    if (event) {
      this.selectedFamilyId = event;
      this.loadCargoTypesByFamilyId();
      this.loadCargoSizesByFamilyId();

      this.form.patchValue({
        typeId: undefined,
        sizeId: '',
      });
    } else {
      this.selectedFamilyId = '';
    }
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.cargo;
    if (!this.isAdd) {
      this.selectedFamilyId = this.data.cargo.familyId;

      if (this.selectedFamilyId) {
        this.loadCargoTypesByFamilyId();
        this.loadCargoSizesByFamilyId();
      }

      this.patchForm(this.data.cargo);
    }
  }

  private loadCargoTypesByFamilyId(): void {
    this.store.dispatch(
      CargoeTypeActions['load_CargoType_List_By-Family_Id']({
        familyId: this.selectedFamilyId,
      })
    );
  }

  private loadCargoSizesByFamilyId(): void {
    this.store.dispatch(
      CargoeSizeActions['load_CargoSize_List_By-Family_Id']({
        familyId: this.selectedFamilyId,
      })
    );
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          CargoesActions.add_Cargo_Success,
          CargoesActions.edit_Cargo_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private patchForm(cargo: Cargo): void {
    this.form.patchValue({
      ccuId: cargo.ccuId,
      ccuName: cargo.ccuName,
      familyId: cargo.familyId,
      sizeId: cargo.sizeId,
      typeId: cargo.typeId,
      vendorId: cargo.vendorId,
      length: cargo.length,
      width: cargo.width,
      height: cargo.height,
      tareMass: cargo.tareMass,
      maxGrossWeight: cargo.maxGrossWeight,
      category: cargo.category,
      isDeckCargo: cargo.isDeckCargo,
      isPool: cargo.isPool,
      description: cargo.description,
      locationId: cargo.locationId,
    });
  }

  saveCargo(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const model = {
        ...this.form.value,
      } as Cargo;

      if (this.isAdd) {
        this.store.dispatch(
          CargoesActions.add_Cargo({
            Cargo: model,
            showPending: this.data.showPending,
            from: this.data.from,
            to: this.data.to,
          })
        );
      } else {
        this.store.dispatch(
          CargoesActions.edit_Cargo({
            Cargo: model,
            CargoId: this.data.cargo.cargoId,
            showPending: this.data.showPending,
            from: this.data.from,
            to: this.data.to,
          })
        );
      }
    }
  }
}
