import {createFeature, createReducer, createSelector} from "@ngrx/store";
import {immerOn} from "ngrx-immer/store";
import {AreaActions} from "../actions/area.actions";
import {siteFeature} from "./site.features";
import {areaInitialState} from "../states/area.state";

export const areaReducer = createReducer(
  areaInitialState,
  immerOn(
    AreaActions.remove_Area,
    AreaActions.load_Areas_By_Site_Id,
    AreaActions.load_Areas,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    AreaActions.set_Area_Id,
    (state, { id }) => {
      state.areaId = id;
    }
  ),
  immerOn(
    AreaActions.load_Areas_By_Site_Id_Success,
    (state, { areas }) => {
      state.areas = areas;
      state.loading.list = false;
    }
  ),
  immerOn(
    AreaActions.load_Areas_Success,
    (state, { areas }) => {
      state.areas = areas;
      state.loading.list = false;
    }
  ),
  immerOn(AreaActions.add_Area_Row, (state) => {
    state.areas.unshift({
      areaId: 'add',
      name: '',
      siteId: '',
      createdDate: null,
      updatedDate: null,
      createdByName: '',
      updatedByName: '',
      deleted: false,
      areaBlockingActivities: null
    });
  }),
  immerOn(AreaActions.remove_Area_Row, (state) => {
    state.areas = state.areas.filter(
      (item) => item.areaId !== 'add'
    );
  }),
  immerOn(
    AreaActions.load_Areas_Failure,
    AreaActions.load_Areas_By_Site_Id_Failure,
    AreaActions.load_Areas_By_Site_Id_Success,
    AreaActions.remove_Area_Success,
    AreaActions.remove_Area_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    AreaActions.add_Area,
    AreaActions.edit_Area,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    AreaActions.add_Area_Success,
    AreaActions.add_Area_Failure,
    AreaActions.edit_Area_Success,
    AreaActions.edit_Area_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const areaFeature = createFeature({
  name: 'area',
  reducer: areaReducer,
  extraSelectors: ({ selectLoading, selectAreas }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectAreas: createSelector(
      selectAreas,
      (areas) => areas
    ),
    selectSite: (site: string[]) =>
      createSelector(siteFeature.selectSites, (sites) =>
        sites.filter((item) => site.includes(item.name))),
  }),
});
