import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoFamily } from '../../interfaces/carrgo-family.interface';

export const CargoeFamilyActions = createActionGroup({
  source: 'CargoFamily',
  events: {
    'load_CargoFamily_List': emptyProps(),
    'load_CargoFamilies_Success': props<{ CargoFamilies: CargoFamily[] }>(),
    'load_CargoFamilies_Failure': errorProps()
  },
});
