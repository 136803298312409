import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoFamilyService } from '../../cargo-family.service';
import { CargoeFamilyActions } from '../actions/cargo-family.actions';
import { CargoFamily } from '../../interfaces/carrgo-family.interface';
import { Store } from '@ngrx/store';
import { cargoFamilyFeature } from '../features/cargo-family.feature';
import { CargoesActions } from '../actions/cargoes.actions';

export const loadCargoFamilies = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeFamilyActions.load_CargoFamily_List,
        CargoesActions.open_Cargo_Dialog,
      ),
      concatLatestFrom(() => store.select(cargoFamilyFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoFamilyService.loadCargoFamilies().pipe(
          map((res: CargoFamily[]) =>
            CargoeFamilyActions.load_CargoFamilies_Success({ CargoFamilies: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeFamilyActions.load_CargoFamilies_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);