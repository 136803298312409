import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoTypeService } from '../../cargo-type.service';
import { CargoeTypeActions } from '../actions/cargo-type.actions';
import { CargoType } from '../../interfaces/carrgo-type.interface';
import { Store } from '@ngrx/store';
import { cargotypeFeature } from '../features/cargo-type.feature';

export const loadCargoTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoTypeService = inject(CargoTypeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeTypeActions.load_CargoType_List,
      ),
      concatLatestFrom(() => store.select(cargotypeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoTypeService.loadCargoTypes().pipe(
          map((res: CargoType[]) =>
            CargoeTypeActions.load_CargoTypes_Success({ CargoTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeTypeActions.load_Cargotypes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoTypesByFamilyId = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoTypeService = inject(CargoTypeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeTypeActions['load_CargoType_List_By-Family_Id'],
      ),
      mergeMap((action) =>
        cargoTypeService.loadCargoTypesByFamilyId(action.familyId).pipe(
          map((res: CargoType[]) =>
            CargoeTypeActions['load_CargoTypes_By-Family_Id_Success']({ CargoTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeTypeActions['load_Cargotypes_By-Family_Id_Failure']({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);