import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoType } from '../../interfaces/carrgo-type.interface';

export const CargoeTypeActions = createActionGroup({
  source: 'CargoType',
  events: {
    'load_CargoType_List': emptyProps(),
    'load_CargoTypes_Success': props<{ CargoTypes: CargoType[] }>(),
    'load_Cargotypes_Failure': errorProps(),
    'load_CargoType_List_By-Family_Id': props<{ familyId: string }>(),
    'load_CargoTypes_By-Family_Id_Success': props<{ CargoTypes: CargoType[] }>(),
    'load_Cargotypes_By-Family_Id_Failure': errorProps(),
    'clear_CargoTypes': emptyProps(),
  },
});
