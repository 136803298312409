import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CargoType } from './interfaces/carrgo-type.interface';

@Injectable({ providedIn: 'root' })
export class CargoTypeService {
  private readonly http = inject(HttpClient);

  loadCargoTypes(): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(`/api/CargoType`);
  }

  loadCargoTypesByFamilyId(familyId: string): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(`/api/CargoType/byFamily?familyId=${familyId}`);
  }
}
