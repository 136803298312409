import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoSizeService } from '../../cargo-size.service';
import { CargoeSizeActions } from '../actions/cargo-size.actions';
import { CargoSize } from '../../interfaces/carrgo-size.interface';
import { Store } from '@ngrx/store';
import { cargoSizeFeature } from '../features/cargo-size.feature';

export const loadCargoSizes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoSizeService = inject(CargoSizeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeSizeActions.load_CargoSize_List,
      ),
      concatLatestFrom(() => store.select(cargoSizeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoSizeService.loadCargoSizes().pipe(
          map((res: CargoSize[]) =>
            CargoeSizeActions.load_CargoSizes_Success({ CargoSizes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeSizeActions.load_CargoSizes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoSizesByFamilyId = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoSizeService = inject(CargoSizeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeSizeActions['load_CargoSize_List_By-Family_Id'],
      ),
      mergeMap((action) =>
        cargoSizeService.loadCargoSizesByFamilyId(action.familyId).pipe(
          map((res: CargoSize[]) =>
            CargoeSizeActions['load_CargoSizes_By-Family_Id_Success']({ CargoSizes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeSizeActions['load_CargoSizes_By-Family_Id_Failure']({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);