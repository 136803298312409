import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, map, mergeMap, of} from "rxjs";
import {AreaActions} from "../actions/area.actions";
import {filter, tap} from "rxjs/operators";
import {Area} from "../../interfaces/area.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {AreaService} from "../../area.service";
import {areaFeature} from "../features/area.features";
import {
  AreaAddEditComponent
} from "../../../../../../../../apps/allocate/src/app/maintenance/shared/components/area-add-edit/area-add-edit.component";

export const loadAreas = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    areaService = inject(AreaService)
  ) => {
    return actions.pipe(
      ofType(
        AreaActions.set_Area_Id,
        AreaActions.load_Areas,
        AreaActions.remove_Area_Success,
        AreaActions.add_Area_Success,
        AreaActions.edit_Area_Success
      ),
      concatLatestFrom(() =>
        store.select(areaFeature.selectAreaId)
      ),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        areaService.loadAreasBySiteId(action).pipe(
          map((res: Area[]) =>
            AreaActions.load_Areas_Success({
              areas: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.load_Areas_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAreaBySiteId = createEffect(
  (
    actions$ = inject(Actions),
    areaService = inject(AreaService)
  ) => {
    return actions$.pipe(
      ofType(
        AreaActions.load_Areas_By_Site_Id,),
      mergeMap((action) =>
        areaService.loadAreasBySiteId(action.id).pipe(
          map((areas: Area[]) =>
            AreaActions.load_Areas_By_Site_Id_Success({
              areas,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AreaActions.load_Areas_By_Site_Id_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeArea = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(AreaService)
  ) => {
    return actions.pipe(
      ofType(AreaActions.remove_Area),
      mergeMap((action) =>
        areaService.removeArea(action.id).pipe(
          map((res: Area) =>
            AreaActions.remove_Area_Success({
              area: res,
              successMessage: 'Area removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.remove_Area_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addArea = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(AreaService)
  ) => {
    return actions.pipe(
      ofType(AreaActions.add_Area),
      mergeMap((action) =>
        areaService
          .addArea(action.area)
          .pipe(
            map((res: Area) =>
              AreaActions.add_Area_Success({
                area: res,
                successMessage: 'Area added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                AreaActions.add_Area_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editArea = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(AreaService)
  ) => {
    return actions.pipe(
      ofType(AreaActions.edit_Area),
      mergeMap((action) =>
        areaService
          .editArea(action.id, action.area)
          .pipe(
            map((res: Area) =>
              AreaActions.edit_Area_Success({
                area: res,
                successMessage: 'Area edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                AreaActions.edit_Area_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openAreaDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(AreaActions.open_Area_Dialog),
      tap((action) => {
        dialog.open(AreaAddEditComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            id: action.id,
            site: action.site,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
