import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CargoSize } from './interfaces/carrgo-size.interface';

@Injectable({ providedIn: 'root' })
export class CargoSizeService {
  private readonly http = inject(HttpClient);

  loadCargoSizes(): Observable<CargoSize[]> {
    return this.http.get<CargoSize[]>(`/api/CargoSize`);
  }

  loadCargoSizesByFamilyId(familyId: string): Observable<CargoSize[]> {
    return this.http.get<CargoSize[]>(`/api/CargoSize/byFamily?familyId=${familyId}`);
  }
}
